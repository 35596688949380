<template>
  <main id="customers-add">
    <PageHeader :items="breadcrumb" />

    <b-row>
      <b-col>
        <b-card title="Cadastro de prospecto">
          <p>Preencha os campos abaixo e cadastre um novo prospecto.</p>

          <AddForm />
        </b-card>
      </b-col>
    </b-row>
  </main>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import AddForm from '@/components/Customers/AddForm';

export default {
  name: 'CustomersAddPage',
  components: {
    PageHeader,
    AddForm
  },
  data() {
    return {
      breadcrumb: [
        {
          text: 'Inicial',
          href: '/'
        },
        {
          text: 'Novo cliente',
          active: true
        }
      ]
    };
  }
};
</script>
